// ReactJS libraries
import React from 'react';

// Import files
import { Container, ThemeToggle } from './Navbar.style';

export default function Navbar(params) {
    return (
        <Container>
            <ThemeToggle title="切換主題" onClick={ params.toggleTheme }> { params.theme === 'dark' ? 'night 🌙' : 'day ☀️' }  </ThemeToggle>
        </Container>   
    );
}