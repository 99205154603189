import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 30px;
    flex: 0 0 auto;
    background-color: ${({ theme }) => theme.footerBackground }
`
export const TextContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5vmax;

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        padding: 0 5vmax;
        justify-content: start;
    }
`

export const MainText = styled.p`
    font-family: 'jf-openhuninn';
    font-size: 0.7em;
    font-color: ${({ theme }) => theme.text };
    opacity: .5;

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        font-size: 0.8em;
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.text };
    }

    a:hover {
        text-decoration: underline;
    }
`