import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    min-height: 50px;
    align-items: center;

    @media (min-width: 768px) {
        flex: 0 1 auto;
    }
`

export const ThemeToggle = styled.button`
    position: absolute;
    right: 15px;
    border: 0;
    background-color: transparent;
    outline: none;
    color: ${({ theme }) => theme.buttonText };
    text-decoration: none;
    opacity: .2;
    cursor: pointer;
`