import { createGlobalStyle } from "styled-components";
import jfOpenHuninn from "../fonts/jf-openhuninn-1.1.ttf";

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'jf-openhuninn';
        src: url(${ jfOpenHuninn }) format('truetype');
    }

    * {
        margin: 0;
        padding: 0;
    }

    body {
        height: 100vh;
        background: ${({ theme }) => theme.body};
        transition: all 0.35s linear;
    }

    p {
        color: ${({ theme }) => theme.text};
    }
`;