import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex: 1 0;
    flex-flow: column-reverse;
    background-color: none;

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        flex: 1 1 auto;
        flex-flow: row;
        margin-bottom: 0;
        overflow: hidden;
    }

    @media (min-width: 768px) and (max-height: 580px) {
        flex: 1 0 30vmax;
    }

    @media (min-width: 1920px) {
        width: 90%;
    }
`
export const InfoContainer = styled.div`
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
`
export const Info = styled.div`
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    font-family: 'jf-openhuninn';

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        width: 100%;
        padding-left: 5vmax;
    }
`

export const Title = styled.h1`
    width: 100%;
    color: ${({ theme }) => theme.titleText };
    opacity: .5;
    font-size: 8vmin;
    line-height: 1em;
    padding-bottom: 0.5em;
    font-family: 'Titan One';

    .name:hover {
        color: #FD7102;
        transition: color 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    }

    // em {
    //     position: relative;
    //     font-style: normal;
    // }

    // em::after {
    //     content: "";
    //     display: inline-block;
    //     width: 10vw;
    //     height: 4vw;
    //     position: absolute;
    //     background-image: url(${ prams => prams.lineImage });
    //     background-size: 10vw 4vw;
    //     background-repeat: no-repeat;
    //     z-index: -1;
    //     // left: -35%;
    //     // top: 30%;

    //     @media (min-width: 768px) {
    //         width: 102px;
    //         height: 40px;
    //         background-size: 102px 40px;
    //     }
    // }

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        font-size: 8vmax;
    }

    @media (min-width: 768px) and (max-height: 580px) {
        font-size: 4em;
    }
`
export const MainText = styled.p`
    width: 100%;
    font-size: 3vmin;

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        font-size: 2vmax;
    }

    @media (min-width: 768px) and (max-height: 580px) {
        font-size: 1.5em;
    }
`

export const Social = styled.p`
    width: 100%;
    font-size: 2vmin;
    padding-top: 3em;
    opacity: .3;

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.text };
    }

    a:hover {
        text-decoration: underline;
    }

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        font-size: 1.5vmax;
    }

    @media (min-width: 768px) and (max-height: 580px) {
        font-size: 1em;
    }
`


export const MainImageContainer = styled.div`
    // background-color: red;
    position: relative; 
    width: 100%;
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        max-width: 40%;
        margin: 2.5vmax 5vmax;
    }

    @media (min-width: 768px) and (max-height: 580px) {
        max-width: 40%;
    }
`

// export const BackgroundImage = styled.div`
//     position: absolute;
//     max-width: 100%;
//     max-height: 100%;
//     object-fit: contain;
//     content: url(${({ theme }) => theme.backgroundImage })
// `

export const MainImage = styled.img`
    position: absolute;
    width: auto;
    height: 100%;
    filter: drop-shadow(0px 0px 15px ${({ theme }) => theme.dropShadow });

    @media (min-width: 768px),
    (max-width: 768px) and (orientation:landscape) {
        position: relative;
        width: 100%;
        height: 150%;
        object-fit: contain;
        animation: appear 2s cubic-bezier(0.22, 1, 0.36, 1) forwards;

        @keyframes appear {
            from {
                opacity: 0;
                bottom: -150%;
            }
            to {
                opacity: 1;
                bottom: -30%;
            }
        }
    }

    &:hover {
        transform: translate(0, 3px);
    }
`

// export const MainImage = styled.img`
//     position: relative;
//     width: auto;
//     height: 20vmax;
//     object-fit: contain;
//     right: -10vmax;
//     animation: appear 2s cubic-bezier(0.22, 1, 0.36, 1) forwards;

//     @media (min-width: 768px),
//     (max-width: 768px) and (orientation:landscape) {
//         width: 30%;
//         height: 50%;
//         max-width: 100%;
//         max-height: 100%;
//         right: -20%;
//     }

//     @media (min-width: 768px) and (max-height: 580px) {
//         width: 30%;
//         top: 15% !important;
//         right: -13%;
//     }
    
//     @keyframes appear {
//         from {
//             opacity: 0;
//             top: 0;
//         }
//         to {
//             opacity: 1;
//             top: 5vmax;
//         }
//     }
// `