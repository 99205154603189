export const light = {
    body: '#D9E9F5',
    text: '#444444',
    titleText: '#444444',
    buttonText: '#444444',
    dropShadow: 'rgba(0, 0, 0, .4)',
    footerBackground: '#FFC085'
};

export const dark = {
    body: '#1b2124',
    text: '#fffcf2',
    titleText: '#fffcf2',
    buttonText: '#fffcf2',
    dropShadow: 'rgba(255, 255, 255, .15)',
    footerBackground: '#E08F42'
};