// ReactJS libraries
import React from 'react';

// Import files
import { Container, InfoContainer, Info, Title, MainText, Social, MainImageContainer, MainImage } from './Content.style';
// import lineImage from "./line.png"
import mainImage from "../../assets/ar-holding-coffee.webp"

export default function Content() {
    return (
        <Container>
            <InfoContainer>
                <Info>
                    <Title><span class="name">ar</span><br />the tiger</Title>
                    <MainText>
                        我是來自海灣地區的孟加拉虎，<br />
                        喜歡工程、音樂和熱帶風情。
                    </MainText>
                    <Social>
                        <a href="https://twitter.com/arthetiger_" target="_blank" rel="noreferrer noopener">@arthetiger_</a>
                    </Social>
                </Info>
            </InfoContainer>
            <MainImageContainer>
                <MainImage src={ mainImage } />
            </MainImageContainer>
        </Container>
    );
}