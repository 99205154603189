// ReactJS libraries
import React from 'react';

// Import files
import { Container, TextContainer, MainText } from './Footer.style';

export default function Footer() {
    return (
        <Container>
            <TextContainer>
                <MainText>
                    Made with &#128062; Paws by <a href="https://arthurchen.me/" target="_blank" rel="noreferrer noopener">Arthur Chen</a> | Art by <a href="https://slow.work" target="_blank" rel="noreferrer noopener">slow.work</a>
                </MainText>
            </TextContainer>
        </Container>
    )
}