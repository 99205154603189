// ReactJS libraries
import React, { useState, useEffect } from 'react';

// Import files
import Navbar from './components/Navbar/Navbar';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import { GlobalStyles } from './styles/global';
import { ThemeProvider } from 'styled-components';
import { light, dark } from './styles/theme'

import './App.css';

function App() {
  // Setup Light/Dark mode
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }

  useEffect(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme = { theme === 'light' ? light : dark }>
        <Navbar toggleTheme = { toggleTheme } theme = { theme } />
        <Content />
        <Footer />
        <GlobalStyles />
      </ThemeProvider>
    </div>
  );
}

export default App;
